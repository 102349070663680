import React from "react";
import MainWrap from "../components/MainWrap";
import YearsInBusiness from "../components/YearsInBusiness";

const settings = {
  name: "Maintenance",
  img1: "maint1.jpg",
  img2: "maint2.jpg",
  title: "Helicopter and Airplane Maintenance",
  heading: "Helicopter and Airplane Maintenance",
  subheadIndex: 4,
  subheadArrow: "Aircraft Maintenance",
  subheadText:
    "Since August of 1988, Bijan Air has maintained numerous aircraft from different manufacturers, fixed wing and rotorcraft.",
  msg: "Our Expertise"
};

const EngPage = ({ location }) => (
  <MainWrap settings={settings} location={location}>
    <h3>Our Expertise</h3>

    <p>
      Since August of 1988, <em>Bijan Air</em> has maintained numerous
      helicopters from different manufacturers, including R-22's , R-44's, Bell
      206, MD500, AS350's, Agusta 109, Schweizer 300, 300CB, 333.
    </p>
    <p>
      With over <YearsInBusiness /> years combined of helicopter maintenance
      experience, the technicians at <em>Bijan Air</em> are factory trained for
      Robinson helicopters, McDonnell Douglass helicopters, American
      Eurocopters, Agusta helicopters, Schweizer helicopters, Rolls-Royce
      engines, Turbomeca engines and more. We've completed many 100 hrs,
      annuals, T inspections, and numerous unscheduled maintenances.
    </p>
    <p>
      One of the biggest assets of this company is the comprehensiveness of
      maintenance procedures. Nothing leaves our hangar until the helicopter is
      test flown before and after maintenance, proper maintenance is completed,
      all paperwork is finished, and all AD &amp; SB research and compliance is
      completed. We are extremely proud of our record of maintaining many
      hundreds of helicopters for various clients with <b>zero</b> accidents or
      returns due to faulty maintenance.
    </p>
  </MainWrap>
);

export default EngPage;
