import React from "react";

class YearsInBusiness extends React.PureComponent {
  render() {
    const year = new Date().getFullYear();
    const yearsInBusiness = year - 1988;

    return <>{yearsInBusiness} </>;
  }
}

export default YearsInBusiness;
